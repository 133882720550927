<template>
  <layout :title="$route.name" :options="options">
    <div class="expande-horizontal column pa-6">
      <div class="expande-horizontal centraliza px-3 column">
        <v-avatar size="60" style="border: 3px solid #fff;">
          <v-img
            src="https://i.pinimg.com/originals/7c/c7/a6/7cc7a630624d20f7797cb4c8e93c09c1.png"
          ></v-img>
        </v-avatar>
        <h1 class="mt-3 white--text">{{ getUser.name }}</h1>
        <span class="fonte orange--text">Corretora: {{ getUser.a_id }}</span>
      </div>

      <div class="expande-horizontal wrap">
        <v-flex xs12 md3 class="pa-1">
          <v-card class="expande-horizontal centraliza column pa-2">
            <h1 class="fonte green--text">
              {{ $helper.formataSaldo(getUser.total_value_investment.balance) }}
            </h1>
            <span class="fonte">RENTABILIDADE</span>
            <v-flex xs12>
              <div
                class="expande-horizontal"
                style="height: 300px; overflow: auto;"
              >
                <v-list three-line dense>
                  <template
                    v-for="(item, index) in getUser.total_value_investment
                      .extract"
                  >
                    <v-list-item :key="item._id">
                      <v-list-item-content>
                        <v-list-item-title class="fonte green--text">
                          {{ $helper.formataSaldo(item.value) }}
                        </v-list-item-title>
                        <v-list-item-subtitle class="fonte">
                          {{ item.description }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle class="fonte orange--text">
                          {{
                            $moment(item.created_at).format(
                              "DD/MM/YYYY [às] HH:mm"
                            )
                          }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn
                          icon
                          x-small
                          @click="
                            createConfirmAction({
                              icon: 'mdi-check',
                              message: 'Deseja remover esta transação?',
                              action: 'deleteTransaction',
                              action_value: item
                            })
                          "
                        >
                          <v-icon> mdi-delete-outline </v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider :key="index"></v-divider>
                  </template>
                </v-list>
              </div>
            </v-flex>
          </v-card>
        </v-flex>
        <v-flex xs12 md3 class="pa-1">
          <v-card class="expande-horizontal centraliza column pa-2">
            <h1 class="fonte green--text">
              {{
                $helper.formataSaldo(getUser.total_withdraw_investment.balance)
              }}
            </h1>
            <span class="fonte">SAQUES DA RENTABILIDADE </span>
            <v-flex xs12>
              <div
                class="expande-horizontal"
                style="height: 300px; overflow: auto;"
              >
                <v-list three-line dense>
                  <template
                    v-for="(item, index) in getUser.total_withdraw_investment
                      .extract"
                  >
                    <v-list-item :key="item._id">
                      <v-list-item-content>
                        <v-list-item-title class="fonte green--text">
                          {{ $helper.formataSaldo(item.value) }}
                        </v-list-item-title>
                        <v-list-item-subtitle class="fonte">
                          {{ item.description }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle class="fonte orange--text">
                          {{
                            $moment(item.created_at).format(
                              "DD/MM/YYYY [às] HH:mm"
                            )
                          }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider :key="index"></v-divider>
                  </template>
                </v-list>
              </div>
            </v-flex>
          </v-card>
        </v-flex>
        <v-flex xs12 md3 class="pa-1">
          <v-card class="expande-horizontal centraliza column pa-2">
            <h1 class="fonte green--text">
              {{ $helper.formataSaldo(getUser.total_value_unilevel.balance) }}
            </h1>
            <span class="fonte">UNILEVEL</span>
            <v-flex xs12>
              <div
                class="expande-horizontal"
                style="height: 300px; overflow: auto;"
              >
                <v-list three-line dense>
                  <template
                    v-for="(item, index) in getUser.total_value_unilevel
                      .extract"
                  >
                    <v-list-item :key="item._id">
                      <v-list-item-content>
                        <v-list-item-title class="fonte green--text">
                          {{ $helper.formataSaldo(item.value) }}
                        </v-list-item-title>
                        <v-list-item-subtitle class="fonte">
                          {{ item.description }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle class="fonte orange--text">
                          {{
                            $moment(item.created_at).format(
                              "DD/MM/YYYY [às] HH:mm"
                            )
                          }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider :key="index"></v-divider>
                  </template>
                </v-list>
              </div>
            </v-flex>
          </v-card>
        </v-flex>
        <v-flex xs12 md3 class="pa-1">
          <v-card class="expande-horizontal centraliza column pa-2">
            <h1 class="fonte green--text">
              {{
                $helper.formataSaldo(getUser.total_withdraw_unilevel.balance)
              }}
            </h1>
            <span class="fonte">SAQUES DO UNILEVEL</span>
            <v-flex xs12>
              <div
                class="expande-horizontal"
                style="height: 300px; overflow: auto;"
              >
                <v-list three-line dense>
                  <template
                    v-for="(item, index) in getUser.total_withdraw_unilevel
                      .extract"
                  >
                    <v-list-item :key="item._id">
                      <v-list-item-content>
                        <v-list-item-title class="fonte green--text">
                          {{ $helper.formataSaldo(item.value) }}
                        </v-list-item-title>
                        <v-list-item-subtitle class="fonte">
                          {{ item.description }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle class="fonte orange--text">
                          {{
                            $moment(item.created_at).format(
                              "DD/MM/YYYY [às] HH:mm"
                            )
                          }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn
                          icon
                          x-small
                          @click="
                            createConfirmAction({
                              icon: 'mdi-check',
                              message: 'Deseja remover esta transação?',
                              action: 'deleteTransaction',
                              action_value: item
                            })
                          "
                        >
                          <v-icon> mdi-delete-outline </v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider :key="index"></v-divider>
                  </template>
                </v-list>
              </div>
            </v-flex>
          </v-card>
        </v-flex>
      </div>

      <div class="expande-horizontal wrap mt-6 pa-3">
        <v-flex xs12 class="mb-3">
          <h1 class="fonte grey--text"># Informações do Usuário</h1>
        </v-flex>
        <v-flex class="pr-2" xs12 md3>
          <span class="fonte orange--text"> Nome</span>
          <v-text-field
            solo-inverted
            dark
            flat
            dense
            :color="$theme.primary"
            label="Nome"
            v-model="getUser.name"
          ></v-text-field>
        </v-flex>
        <v-flex class="pr-2" xs12 md3>
          <span class="fonte orange--text"> CPF</span>
          <v-text-field
            solo-inverted
            dark
            readonly
            flat
            dense
            :color="$theme.primary"
            label="Login"
            v-model="getUser.cpf"
          ></v-text-field>
        </v-flex>
        <v-flex class="pr-1" xs12 md3>
          <span class="fonte orange--text"> Login</span>
          <v-text-field
            solo-inverted
            dark
            flat
            dense
            :color="$theme.primary"
            label="Login"
            readonly
            v-model="getUser.nickname"
          ></v-text-field>
        </v-flex>
        <v-flex class="px-1" xs12 md3>
          <span class="fonte orange--text"> E-mail</span>
          <v-text-field
            solo-inverted
            dark
            flat
            readonly
            dense
            :color="$theme.primary"
            label="Email"
            v-model="getUser.email"
          ></v-text-field>
        </v-flex>
        <v-flex class="px-1" xs12 md3>
          <span class="fonte orange--text"> Contato</span>
          <v-text-field
            solo-inverted
            dark
            flat
            dense
            :color="$theme.primary"
            label="Contato"
            v-model="getUser.phone"
            v-mask="['(##) # ####-####']"
          ></v-text-field>
        </v-flex>
      </div>

      <div class="expande-horizontal wrap mt-6">
        <v-flex xs12>
          <h1 class="fonte grey--text pa-4">
            # Compras - {{ getPurchases.length }}
          </h1>
        </v-flex>
        <template v-for="purchase in getPurchases">
          <PurchaseCareer
            :key="purchase.item._id"
            :purchase="purchase"
            v-if="purchase.purchase_type === 'career'"
          />
          <PurchaseInvestment
            :key="purchase.item._id"
            :purchase="purchase"
            v-if="purchase.purchase_type === 'investment'"
          />
          <PurchaseProduct
            :key="purchase.item._id"
            :purchase="purchase"
            v-if="purchase.purchase_type === 'product'"
          />
        </template>
      </div>

      <v-divider dark class="my-3"></v-divider>

      <v-flex xs12>
        <h1 class="fonte grey--text pa-4">
          # Progresso dos planos
        </h1>
      </v-flex>

      <div class="expande-horizontal wrap">
        <v-flex
          class="pa-6"
          v-for="purchase in getPurchases"
          :key="purchase._id"
          xs12
          md3
        >
          <v-card color="grey darken-3">
            <v-flex xs12>
              <div class="expande-horizontal wrap column">
                <div class="expande-horizontal pa-2">
                  <span class="fonte white--text px-2"
                    >{{ purchase.item.title }} -
                    {{ purchase.item.description }}</span
                  >
                  <v-spacer></v-spacer>
                  <v-btn
                    dark
                    class="elevation-0"
                    :color="getColorByPurchaseProgress(purchase)"
                    x-small
                  >
                    <span class="fonte">
                      {{
                        purchase.current_status === "active"
                          ? "Rendendo"
                          : purchase.current_status
                      }}
                    </span>
                    <span class="fonte" v-if="purchase.total_percent_gained">
                      -
                      {{ parseInt(purchase.total_percent_gained) }} de
                      {{ purchase.item.total_percent_gain }}%
                    </span>
                  </v-btn>
                </div>
                <div class="pa-3 pb-1 pt-0 expande-horizontal">
                  <v-icon class="mr-1" color="orange" size="11"
                    >mdi-star</v-icon
                  >
                  <span style="font-size: 9pt;;" class="fonte orange--text">
                    Dia de saque:
                    {{ $moment(purchase.due_date).format("DD") }} + 3 dias
                    subsequentes
                  </span>
                </div>
                <div class="pa-3 pb-1 pt-0 expande-horizontal">
                  <span style="font-size: 9pt;" class="fonte green--text">
                    Saldo Disponível: $
                    {{ $toLocaleString(purchase.withdraw_available_value?purchase.withdraw_available_value:0) }}
                  </span>
                </div>
                <div class="pa-3 pb-1 pt-0 expande-horizontal">
                  <span style="font-size: 9pt;;" class="fonte red--text">
                    Saques: $
                    {{ $toLocaleString(purchase.withdraw_available_fired?purchase.withdraw_available_fired:0) }}
                  </span>
                </div>
                <v-progress-linear
                  :color="getColorByPurchaseProgress(purchase)"
                  height="15"
                  stream
                  :value="purchase.total_percent_gained / 2.4"
                  :buffer-value="purchase.total_percent_gained / 2.4"
                  striped
                >
                  <template v-slot:default="{ value }">
                    <span class="white--text fonte font-weight-light"
                      >{{ parseInt(purchase.total_percent_gained) }} de
                      {{ purchase.item.total_percent_gain }}%</span
                    >
                  </template>
                </v-progress-linear>
              </div>
            </v-flex>
          </v-card>
        </v-flex>
      </div>

      <v-divider dark class="my-3"></v-divider>

      <div class="expande-horizontal wrap mt-6">
        <v-flex xs12>
          <h1 class="fonte grey--text pa-7">
            # Extratos - {{ getTransactions.length }}
          </h1>
        </v-flex>
        <v-flex xs12 class="pa-6 pt-3">
          <v-simple-table
            dense
            style="width: 100%;"
            v-if="getTransactions.length > 0"
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th>Valor</th>
                  <th>Descrição</th>
                  <th>Data</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  style="cursor: pointer"
                  @click="abreNotificacao(item)"
                  v-for="item in getTransactions"
                  :key="item.uid"
                >
                  <td class="font-weight-bold fonteCorpo green--text">
                    $ {{ item.value }}
                  </td>
                  <td class="font-weight-bold fonteCorpo">
                    {{ item.description }}
                  </td>
                  <td class="font-weight-bold grey--text fonteCorpo">
                    {{
                      $moment(item.created_at)
                        .locale("pt-br")
                        .format("llll")
                    }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <Empty v-else></Empty>
        </v-flex>
      </div>

      <div class="expande-horizontal wrap mt-6">
        <v-flex xs12>
          <h1 class="fonte grey--text pa-7">
            # Rede - {{ getUser.rede.length }}
          </h1>
        </v-flex>
        <v-flex xs12 class="pa-6 pt-3">
          <v-list class="pa-0" dark v-if="getUser.rede.length > 0">
            <template v-for="(item, index) in getUser.rede">
              <v-list-item :key="item._id">
                <v-avatar>
                  <v-icon>mdi-account-circle</v-icon>
                </v-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.indicated_name }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ item.indicated_phone }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle class="fonte orange--text">
                    Desde
                    {{
                      $moment(item.created_at).format("DD/MM/YYYY [às] HH:mm")
                    }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider :key="index"></v-divider>
            </template>
          </v-list>

          <Empty v-else></Empty>
        </v-flex>
      </div>
      <ModalAddBalance />
      <ModalUpdatePass />
    </div>
  </layout>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import PurchaseCareer from "@/apps/11#purchases/components/PurchaseCareer";
import PurchaseInvestment from "@/apps/11#purchases/components/PurchaseInvestment";
import PurchaseProduct from "@/apps/11#purchases/components/PurchaseProduct";
import ModalAddBalance from "@/apps/2#users/components/modalAddBalance";
import ModalUpdatePass from "@/apps/2#users/components/modalUpdatePass";

export default {
  props: ["id"],
  components: {
    PurchaseCareer,
    PurchaseInvestment,
    PurchaseProduct,
    ModalAddBalance,
    ModalUpdatePass
  },
  data() {
    return {
      options: [
        {
          nome: "Ativar Rede",
          action: () => this.enableUnilevel()
        },
        {
          nome: "Desativar Rede",
          action: () => this.disableUnilevel()
        },
        {
          nome: "Salvar Alterações",
          action: () => this.updateUser()
        },
        // {
        //   nome: "Add Saldo",
        //   action: () => this.abreModalAddBalance()
        // },
        {
          nome: "Bloquear",
          action: () => this.blockUser()
        },
        {
          nome: "Desbloquear",
          action: () => this.unblockUser()
        },
        {
          nome: "Atualizar Senha",
          action: () => this.abreModalUpdatePass()
        }
      ]
    };
  },
  computed: {
    ...mapGetters(["getUser", "getPurchases", "getTransactions"])
  },
  methods: {
    ...mapActions([
      "viewUser",
      "createConfirmAction",
      "enableUnilevel",
      "disableUnilevel",
      "updateUser",
      "abreModalAddBalance",
      "blockUser",
      "unblockUser",
      "abreModalUpdatePass",
      "deleteTransaction"
    ]),
    getColorByPurchaseProgress(purchase) {
      if (purchase.total_percent_gained === purchase.item.total_percent_gain) {
        return "orange";
      }

      if (
        purchase.total_percent_gained &&
        purchase.total_percent_gained < purchase.item.total_percent_gain
      ) {
        return "green";
      }

      if (purchase.total_percent_gained === 0) {
        return "red";
      }
    }
  },
  created() {
    this.viewUser({ _id: this.id });
  }
};
</script>
