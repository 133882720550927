<template>
  <v-dialog
    width="700"
    persistent
    transition="slide-x-transition"
    v-model="getModalAddBalance"
  >
    <v-card dark class="expande-horizontal wrap">
      <div class="expande-horizontal px-6 py-3">
        <v-btn dark @click="fechaModalAddBalance" icon>
          <v-icon color="grey">
            mdi-chevron-left
          </v-icon>
        </v-btn>
        <span style="font-size: 17pt;" class="fonte white--text">
          Saldo </span
        >2
        <div class="expande-horizontal"></div>
        <v-btn class="elevation-3" icon @click="fechaModalAddBalance">
          <v-icon color="red darken-3">
            mdi-close
          </v-icon>
        </v-btn>
      </div>

      <div class="expande-horizontal wrap py-6 pb-0">
        <v-flex xs12>
          <v-form ref="form">
            <v-flex class="px-7" xs12>
              <span class="py-3 fonte" :style="`color: ${$theme.primary}`">
                Descrição
              </span>
              <v-text-field
                v-model="getAddBalance.description"
                :rules="[v => !!v || 'preencha aqui']"
                dense
                solo-inverted
                flat
                :color="$theme.primary"
              ></v-text-field>
            </v-flex>

            <v-flex class="px-7" xs12>
              <span class="py-3 fonte" :style="`color: ${$theme.primary}`">
                Tipo
              </span>
              <v-select
                v-model="getAddBalance.transaction_type"
                :rules="[v => !!v || 'preencha aqui']"
                dense
                :items="['investment', 'unilevel']"
                solo-inverted
                placeholder="Escolha a destinação do saldo"
                flat
                :color="$theme.primary"
              ></v-select>
            </v-flex>

            <v-flex
              v-if="getAddBalance.transaction_type === 'investment'"
              class="px-7"
              xs12
            >
              <span class="fonte">
                Escolha a conta para o saldo ser adicionado
              </span>
              <v-list>
                <template v-for="item in getUser.purchases">
                  <v-list-item
                    class="mb-2"
                    @click="setPurchaseId(item)"
                    style="border-radius: 6px; border: 1px solid grey; padding: 6px;"
                    v-if="
                      item.current_status === 'active' &&
                        item.purchase_type === 'investment'
                    "
                    :key="item._id"
                  >
                    <v-avatar class="mr-3" :color="$theme.primary">
                      <v-icon>mdi-crown</v-icon>
                    </v-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ item.item.description }}
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn
                        v-if="getAddBalance.purchase_id === item._id"
                        icon
                        color="green"
                        small
                      >
                        <v-icon> mdi-check </v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </template>
              </v-list>
            </v-flex>

            <v-flex class="px-7" xs12>
              <span class="py-3 fonte" :style="`color: ${$theme.primary}`">
                Valor
              </span>
              <v-text-field
                @keyup.enter="validate"
                v-model="getAddBalance.value"
                :rules="[v => !!v || 'preencha aqui']"
                dense
                solo-inverted
                flat
                type="Number"
                :color="$theme.primary"
                label="ex: 200"
              ></v-text-field>
            </v-flex>
          </v-form>
        </v-flex>
      </div>

      <div class="expande-horizontal">
        <v-btn large dark @click="validate" tile block color="green">
          <span class="fonte"> Salvar </span>
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Template from "../../shared/components/Template.vue";
export default {
  components: { Template },
  computed: {
    ...mapGetters(["getModalAddBalance", "getAddBalance", "getUser"])
  },
  methods: {
    ...mapActions(["addBalance", "fechaModalAddBalance", "createGlobalMessage"]),
    setPurchaseId(item) {
      this.$store.commit("setAddBalance", {
        ...this.getAddBalance,
        purchase_id: item._id
      });
    },
    validate() {
      if (this.$refs.form.validate()) {
        if (this.getAddBalance.transaction_type === "investment") {
          if (!this.getAddBalance.purchase_id) {
            this.createGlobalMessage({
              timeout: 6000,
              message:
                "Escolha uma conta de investimento para adicionar o saldo",
              type: "warning"
            })
          } else {
            this.addBalance(this.getAddBalance);
          }
        } else {
          this.addBalance(this.getAddBalance);
        }
      }
    }
  }
};
</script>
